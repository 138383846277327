import { Typography, Button } from '@mui/material';

const Popup = ({ setOpen }) => {
  return (
    <>
      <div
        className='bg-white text-center p-6 md:p-12 flex flex-col items-center justify-around w-full h-popupmobile md:w-popup md:h-popup'
        style={{ border: '3px #DCE6F5 solid', borderRadius: '16px'}}
      >
        <Typography className='my-2 font-bold' style={{ fontSize: '24px', color: 'rgba(1, 31, 48, 1)' }}>
          Thank you!
        </Typography>
        <Typography className='mt-2 mb-2 md:mb-6 font-bold text-sm md:text-base' style={{ color: '#011F30' }}>
          Your NFT will be minted and airdropped to you within 24 hours. Please contact{' '}
          <span style={{ color: '#3F78A3' }}> customersupport@spores.app</span> if there's anything else we can help.
        </Typography>
        <video
          src="https://spores-marketplace-assets-dev.s3.ap-southeast-1.amazonaws.com/3fc69f28-8603-48ee-9f2c-62461de40603"
          style={{ border: '3px solid rgba(88, 130, 193, 0.28)' }}
          className='mb-2 md:mb-6 w-full rounded-2xl'
          autoPlay
          loop
          controls
          muted
        />
          <div style={{ width: '300px', borderRadius: '4px' }}>
          <Button className='w-full font-bold m-0 p-0' style={{ color: '#ffffff', fontSize: '18px', height: '54px' }}>
            <Typography
              className='bg-black w-full h-full flex justify-center items-center font-bold'
              style={{ borderRadius: '4px' }}
              onClick={() => {
                setOpen(false);
              }}
            >
              DONE
            </Typography>
          </Button>
        </div>
      </div>
    </>
  );
};

export default Popup;
