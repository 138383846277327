import React from 'react';
import { useDispatch } from 'react-redux';
import { useAlert } from 'hooks';
import { signIn } from 'reducers/profileSlice';
import { marketService } from 'services/market';
import jwtDecode from 'jwt-decode';

const Controller = ({ children }) => {
  useAlert();

  const dispatch = useDispatch();
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    try {
      const profile = JSON.parse(localStorage.getItem('profile'));
      jwtDecode(profile.access_token);
      dispatch(signIn(profile));
      marketService.getQuota().then(({ name, product_can_buy }) => {
        dispatch(signIn({ tier: name, isVip: !!name, products: product_can_buy }));
      });
    } catch {
    } finally {
      setIsReady(true);
    }
  }, [dispatch]);

  return isReady && children;
};

export default Controller;
