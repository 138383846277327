import React from 'react';
import { Link as MuiLink } from '@mui/material';

const Support = () => {
  return (
    <div
      className=''
      style={{
        position: 'sticky',
        top: '70vh',
        zIndex: '1',
      }}
    >
      <div className='grid justify-center content-around mr-2 md:mr-6' style={{ position: 'absolute', right: 1 }}>
        <MuiLink
          href='https://t.me/sporeseng'
          className='opacity-80 hover:opacity-100 grid justify-center'
          title='Telegram'
        >
          <img
            className='w-full, h-full'
            src={require('assets/designs/support.svg').default}
            style={{ height: '60px', width: '60px' }}
          />
        </MuiLink>
      </div>
    </div>
  );
};

export default Support;
