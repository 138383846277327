import React from 'react';
import {
  DialogActions,
  FormControl,
  TextField,
  Typography,
  Button,
  Dialog,
  Link as MuiLink,
  InputAdornment,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Popup from '../../components/Popup';
import { marketService } from 'services/market';
import { isValidAddress, isValidCode } from 'utils/validator';
import { useMutation } from 'react-query';
import { storageService } from 'services/storage';
import WarningIcon from '@mui/icons-material/Warning';
import SocialMobile from '../../components/SocialBarMobile';

const Home = ({ onClose, onSuccess, onRefresh }) => {
  const { handleSubmit, control } = useForm();
  const [isOpen, setIsOpen] = React.useState(false);

  const { mutate: submit, isLoading } = useMutation(marketService.submit, {
    onSuccess: () => {
      setIsOpen(true);
      onClose();
      onSuccess();
      onRefresh();
      storageService.clearOrder();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      submit({
        code: values.code,
        walletAddr: values.walletAddr,
      });
    })();
  };

  return (
    <div className='text-white' style={{ backgroundColor: '#020206' }}>
      <div
        className='relative flex items-center px-6 w-full bg-cover h-mobile md:h-desktop'
        style={{
          display: 'flex',
          background: `url(${require('assets/projects/background1.png').default}) no-repeat`,
          backgroundPosition: 'center bottom',
          flexDirection: 'column',
        }}
      >
        <div className='flex justify-center'>
          <img src={require('assets/projects/marseratiXspores.png').default} className='mt-10 mx-4' />
        </div>
        <Typography
          className='text-2xl md:text-5xl font-bold mt-6 mb-2 flex justify-center text-center'
          style={{ textShadow: '-1px 1px 10px #5882C1' }}
        >
          Maserati Grecale NFT Claiming
        </Typography>
        <div
          className='flex flex-col items-center rounded-lg py-6 px-4 md:px-16 mt-10 w-full sm:w-11/12 md:w-10/12 lg:w-8/12 xl:w-7/12 2xl:w-6/12'
          style={{ backgroundColor: 'rgba(88, 130, 193, 0.28)', border: '3px #48648E solid' }}
        >
          <Controller
            name='code'
            defaultValue=''
            control={control}
            rules={{
              validate: {
                pattern: (value) =>
                  isValidCode(value) || (
                    <div className='flex justify-between items-center'>
                      <WarningIcon className='mr-2' fontSize='small' />
                      Claim Code is not valid
                    </div>
                  ),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl fullWidth className=''>
                <label className='font-bold py-1' style={{ fontSize: '14px' }}>
                  NFT Claim Code
                </label>
                <TextField
                  placeholder='XXXXXXXX'
                  InputProps={{
                    startAdornment: <InputAdornment position='center'></InputAdornment>,
                    style: { height: '52px', textAlign: 'center' },
                    inputProps: {
                      style: { color: '#549FD8', fontSize: '24px', fontWeight: '700', textAlign: 'center' },
                    },
                  }}
                  style={{
                    background: '#0E1829',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                  }}
                  {...field}
                  error={invalid}
                />
                <div className='flex mt-2' style={{ color: '#FF4841' }}>
                  <Typography className='font-bold' style={{ fontSize: '14px' }}>
                    {' '}
                    {error?.message}
                  </Typography>
                </div>
              </FormControl>
            )}
          />
          <Controller
            name='walletAddr'
            defaultValue=''
            control={control}
            rules={{
              validate: {
                pattern: (value) =>
                  isValidAddress(value) || (
                    <div className='flex justify-between items-center'>
                      <WarningIcon className='mr-2' fontSize='small' />
                      Address is not valid{' '}
                    </div>
                  ),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl fullWidth className='mt-6'>
                <label className='font-bold py-1' style={{ fontSize: '14px' }}>
                  Your Wallet Address
                </label>
                <TextField
                  placeholder='0xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
                  InputProps={{
                    startAdornment: <InputAdornment position='center'></InputAdornment>,
                    style: { height: '52px', textAlign: 'center' },
                    inputProps: {
                      style: { color: '#549FD8', fontSize: '24px', fontWeight: '700', textAlign: 'center' },
                    },
                  }}
                  style={{
                    background: '#0E1829',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                  }}
                  {...field}
                  error={invalid}
                />
                <div className='flex mt-2' style={{ color: '#FF4841' }}>
                  <Typography className='font-bold' style={{ fontSize: '14px' }}>
                    {' '}
                    {error?.message}
                  </Typography>
                </div>
              </FormControl>
            )}
          />

          <DialogActions fullWidth className='flex my-6 w-full'>
            <Button
              loading={isLoading}
              onClick={handleClickSubmit}
              color='info'
              className='text-base w-full font-bold'
              style={{ color: '#ffffff', fontSize: '18px', height: '54px' }}
            >
              SUBMIT
            </Button>
          </DialogActions>
          <div className='flex flex-col md:flex-row text-center'>
            <Typography className='mb-0 mr-1 text-sm md:text-base' paragraph color='silver'>
              Don’t have a wallet?{' '}
            </Typography>
            <MuiLink
              className='hover:underline text-blue-300 text-sm md:text-base'
              href='https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain'
            >
              Please click here for more information.
            </MuiLink>
          </div>
        </div>
        <SocialMobile />
      </div>
      <Dialog open={isOpen} onClose={!isOpen} maxWidth='md'>
        <Popup setOpen={setIsOpen} />
      </Dialog>
    </div>
  );
};

export default Home;
