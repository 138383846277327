import React from 'react';
import { Grid, Button, Link as MuiLink } from '@mui/material';

const Social = () => {
  return (
    <div
      className='hidden md:flex'
      style={{
        position: 'sticky',
        top: '40vh',
        zIndex: '900',
      }}
    >
      <div className='grid justify-center content-around' style={{ backgroundColor: '#242424', height: '252px', width: '67px', position: 'absolute' }}>
        <MuiLink
          href='https://www.linkedin.com/company/SporesNetwork/'
          className='text-gray-200 hover:text-white opacity-50 hover:opacity-100 grid justify-center'
          title='Linkedin'
        >
          <img className='fill-current' src={require('assets/icons/media/Linkedin.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://www.instagram.com/accounts/login/?next=/SporesNetwork/'
          className='text-gray-200 hover:text-white opacity-50 hover:opacity-100 grid justify-center'
          title='Instagram'
        >
          <img className='fill-current' src={require('assets/icons/media/instagram.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://www.youtube.com/channel/UC9pgjF_aWprnVodvxO7OPkA'
          className='text-gray-200 hover:text-white opacity-50 hover:opacity-100 grid justify-center'
          title='Youtube'
        >
          <img className='fill-current' src={require('assets/icons/media/Union.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://www.facebook.com/SporesNetwork'
          className='text-gray-200 hover:text-white opacity-50 hover:opacity-100 grid justify-center'
          title='Facebook'
        >
          <img className='fill-current' src={require('assets/icons/media/Facebook.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://twitter.com/Spores_Network'
          className='text-gray-200 hover:text-white opacity-50 hover:opacity-100 grid justify-center'
          title='Twitter'
        >
          <img className='fill-current ' src={require('assets/icons/media/twitter.svg').default} />
        </MuiLink>
      </div>
    </div>
  );
};

export default Social;
