import { Link } from 'react-router-dom';
import { AppBar, Link as MuiLink, Toolbar, Typography } from '@mui/material';
import { privateRoute } from 'routes';

const Footer = () => {
  return (
    <AppBar position='sticky xl:px-16 pt-4 lg:pt-0 h-24 lg:h-16' elevation={0} style={{ backgroundColor: '#232323'}}>
      <Toolbar className='flex flex-col lg:flex-row'>
        <Link to={privateRoute.home.path}>
          <img src={require('assets/icons/logo-spores-invert.svg').default} className='h-10' />
        </Link>

        <div className='lg:flex-1 flex justify-end text-gray-200 font-bold'>
          <MuiLink href='https://marketplace.spores.app' className='hidden lg:inline text-gray-200 hover:text-white mr-6'>
            Marketplace
          </MuiLink>
          <MuiLink href='https://launchpad.spores.app' className='hidden lg:inline text-gray-200 hover:text-white mr-0 sm:mr-6'>
            Launchpad
          </MuiLink>
          <MuiLink href='https://games.spores.app' className='hidden lg:inline text-gray-200 hover:text-white mr-0 sm:mr-6'>
            Games
          </MuiLink>
          <MuiLink href='https://republique.spores.app/' className='hidden lg:inline text-gray-200 hover:text-white mr-0 sm:mr-6'>
            République
          </MuiLink>
          <Typography className='font-bold'>
            Contact Us:{' '}
            <MuiLink href='mailto:customersupport@spores.app' className='text-gray-200 hover:text-white font-normal'>
              customersupport@spores.app
            </MuiLink>
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
