import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Link as MuiLink, Toolbar, Button, Menu, MenuItem, Divider } from '@mui/material';
import { privateRoute } from 'routes';
import MenuIcon from '@mui/icons-material/Menu';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  console.log(anchorEl);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar position='sticky' elevation={0} style={{ backgroundColor: '#232323' }}>
      <Toolbar className='xl:px-16'>
        <Link to={privateRoute.home.path}>
          <img
            src={require('assets/icons/logo-spores-invert.svg').default}
            className='h-8 sm:h-10'
            style={{ minWidth: '100px' }}
          />
        </Link>

        <div className='hidden md:flex-1 md:flex items-center justify-end'>
          <MuiLink href='https://marketplace.spores.app' className='text-gray-200 hover:text-white mx-3'>
            Marketplace
          </MuiLink>
          <MuiLink href='https://launchpad.spores.app' className='text-gray-200 hover:text-white mx-3'>
            Launchpad
          </MuiLink>
          <MuiLink href='https://games.spores.app' className='text-gray-200 hover:text-white mx-3'>
            Games
          </MuiLink>
          <MuiLink href='https://republique.spores.app/' className='text-gray-200 hover:text-white mx-3'>
            République
          </MuiLink>
          <MuiLink href='https://bridge.spores.app/' className='text-gray-200 hover:text-white mx-3'>
            Bridge
          </MuiLink>
          <div className='flex bg-gray-400 mx-1' style={{ width: '1px', height: '24px' }}></div>
          <MuiLink href='https://spores.app/about' className='text-gray-200 hover:text-white mx-3'>
            About us
          </MuiLink>
        </div>

        <div className='md:hidden flex-1 flex items-center justify-end'>
          <Button
            color='secondary'
            style={{ backgroundColor: 'transparent' }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            {anchorEl == null ? (
              <MenuIcon style={{ color: 'white' }} sx={{ fontSize: 32 }} />
            ) : (
              <CancelOutlinedIcon style={{ color: '#919191', fontWeight: '100' }} sx={{ fontSize: 32 }} />
            )}
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <div className='px-8' style={{ backgroundColor: '#232323' }}>
              <MenuItem className='p-0 w-screen' onClick={handleClose} style={{ backgroundColor: '#232323'}}>
                <MuiLink
                  href='https://marketplace.spores.app'
                  className='text-white hover:text-white py-5 font-medium text-lg'
                >
                  Marketplace
                </MuiLink>
              </MenuItem>
              <Divider style={{ backgroundColor: '#3C3C3C' }} className='m-0' />
              <MenuItem className='p-0 w-screen' onClick={handleClose} style={{ backgroundColor: '#232323' }}>
                <MuiLink
                  href='https://launchpad.spores.app'
                  className='text-white hover:text-white py-5 font-medium text-lg'
                >
                  Launchpad
                </MuiLink>
              </MenuItem>
              <Divider style={{ backgroundColor: '#3C3C3C' }} className='m-0' />
              <MenuItem className='p-0 w-screen' onClick={handleClose} style={{ backgroundColor: '#232323' }}>
                <MuiLink
                  href='https://games.spores.app'
                  className='text-white hover:text-white py-5 font-medium text-lg'
                >
                  Games
                </MuiLink>
              </MenuItem>
              <Divider style={{ backgroundColor: '#3C3C3C' }} className='m-0' />
              <MenuItem className='p-0 w-screen' onClick={handleClose} style={{ backgroundColor: '#232323' }}>
                <MuiLink
                  href='https://republique.spores.app/'
                  className='text-white hover:text-white py-5 font-medium text-lg'
                >
                  République
                </MuiLink>
              </MenuItem>
              <Divider style={{ backgroundColor: '#3C3C3C' }} className='m-0' />
              <MenuItem className='p-0 w-screen' onClick={handleClose} style={{ backgroundColor: '#232323' }}>
                <MuiLink
                  href='https://bridge.spores.app/'
                  className='text-white hover:text-white py-5 font-medium text-lg'
                >
                  Bridge
                </MuiLink>
              </MenuItem>
              <Divider style={{ backgroundColor: '#3C3C3C' }} className='m-0' />
              <MenuItem className='p-0 w-screen' onClick={handleClose} style={{ backgroundColor: '#232323' }}>
                <MuiLink
                  href='https://spores.app/about'
                  className='text-white hover:text-white py-5 font-medium text-lg'
                >
                  About us
                </MuiLink>
              </MenuItem>
            </div>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
